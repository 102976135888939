import { useEffect, useCallback, useMemo, Fragment, lazy } from 'react';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { refreshAvailableLoads, processAvailableLoadsSearchParams } from 'shared/find-loads/redux/find-loads.actions';
import { Page } from 'shared/components/analytics/page.component';
import { DocumentTitle } from 'shared/components/document-title/document-title.component';
import { FindLoadsSearch } from './search/find-loads-search.component';
import { FindLoadsResults } from './results/find-loads-results.component';
import { BackToFindLoadsButton } from './back-to-find-loads-button.component';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { useWindowSizeBreakpoints, breakpoints } from 'app/hooks/use-window-size-breakpoints.hook';
import { useDispatch } from 'react-redux';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { SystemDisruption } from '@shared/components/system-disruption/system-disruption.component';

// lazy load components not needed on startup
const BinConfirmationModal = lazy(() => import('./modals/bin-confirmation-modal.component'));
const BinFormModal = lazy(() => import('./modals/bin-form-modal.component'));
const LoadNoLongerAvailableModal = lazy(() => import('shared/components/modal/load-no-longer-available.component'));
const PostLoginBanner = lazy(() => import('shared/components/banner-post-login/banner-post-login'));
import './find-loads.component.scss';

interface Props {
  searchType?: AvailableLoadSearchType;
  sendLDEvents?: boolean;
}

export const FindLoadsVer2Page = ({ searchType, sendLDEvents }: Props) => {
  const resources = useSelector(state => state.resources);
  const findLoads = useSelector(state => state.findLoads);

  const isWebBreakpoint = useWindowSizeBreakpoints(breakpoints.lg_two_columns_cuttoff);
  const { postBookReloads, findLoadsSystemDisruptionWeb } = useFlags();

  // fix versions that are not able to display the custom scrolling
  const isBrowserSupportedForScrolling = useMemo(() => {
    // documented issues: Safari versions 11/12/13
    const isSafari = navigator?.vendor?.match(/apple/i);

    // check browser support
    if (!isSafari) {
      return true;
    }

    const userAgent = navigator?.userAgent;
    const test = /Version\/(\d+)/g;
    const matchedParts = test.exec(userAgent);

    if (!matchedParts) {
      return false;
    }

    const safariVersion = matchedParts[1];
    // tslint:disable-next-line:radix
    return parseInt(safariVersion) >= 14;
  }, []);

  useEffect(() => {
    // css class needed to limit styles from impacting other pages
    const cssSrollClass = 'find-loads-scrolling';
    if (isWebBreakpoint && isBrowserSupportedForScrolling) {
      document.body.scrollIntoView();
      document.body.classList.add(cssSrollClass);
    } else {
      document.body.classList.remove(cssSrollClass);
    }

    return () => { document.body.classList.remove(cssSrollClass); };
  }, [isWebBreakpoint, isBrowserSupportedForScrolling]);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(processAvailableLoadsSearchParams(window.location.search));
  }, []);

  const hasResources = Boolean(resources && Object.keys(resources).length);
  const [refreshToken, forceRefresh] = useForceRefreshToken();

  const pushRoute = useDispatcher(push);

  // search type needs to be preserved here because it
  // gets reset in the url transition to /find-loads
  const onReset = useCallback(() => {
    pushRoute(`/find-loads/${searchType}`);
    forceRefresh();
  }, [searchType]);

  if (!findLoads) {
    return null;
  }

  const onConfirmModalHide = useCallback(
    () => {
      if (findLoads?.searchCriteria) {
        refreshAvailableLoads();
      }
    },
    [findLoads],
  );

  const onBinModalHide = useCallback(
    (hasError: boolean) => {
      if (hasError) {
        onConfirmModalHide();
      }
    },
    [],
  );

  const isReloadsPage = searchType === AvailableLoadSearchType.RELOADS;

  if (isReloadsPage && !postBookReloads) {
    return null;
  }
  return (
    <Page name="Find Loads Ver 2" className="wide-width-page">
      <DocumentTitle resource='FIND_LOADS' />
      {(findLoadsSystemDisruptionWeb && <SystemDisruption pageNameResourceKey="FIND_LOADS" disruptionType="PAGE_LEVEL_DISRUPTION" />)
        || hasResources &&
        <div className="find-loads">
          <PostLoginBanner />
          {isReloadsPage && <BackToFindLoadsButton />}
          {!isReloadsPage && <FindLoadsSearch onReset={onReset} />}
          <FindLoadsResults searchType={searchType} sendLDEvents={sendLDEvents} />
        </div>
      }
      <LoadNoLongerAvailableModal />
      <BinFormModal onHide={onBinModalHide} />
      <BinConfirmationModal onHide={onConfirmModalHide} />
    </Page>
  );
};