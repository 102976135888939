import { ChangeEvent, FormEvent, useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment';
import 'rxjs/add/observable/forkJoin';
import { resetAvailableLoads, resetErrorMessage, searchModified, getSuggestedLoads, setSearchCorrelationId } from 'shared/find-loads/redux/find-loads.actions';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { FindLoadsSearchForm } from './find-loads-search.form';
import { convertDistanceToMetric } from 'shared/components/formatters/unit-conversion.formatter';
import { getCountryNameBasedOnCode } from '../../find-loads/shared/country.transformer';
import { DateRangePickerV2Value } from 'shared/components/date-picker/date-range-picker/date-range-picker-v2.component';
import { FindLoadsFiltersModal } from '../filters/find-loads-filters-modal.component';
import { FindLoadsFiltersForm } from '../filters/find-loads-filters.form';
import { defaultSearchCriteria } from '../../find-loads/available-loads-search.criteria';
import { LoadNotFoundBanner } from './load-not-found-banner.component';
import { EquipmentTypeValues } from 'shared/find-loads/components/equipment-select.component';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { setSubmitSearchEvent } from 'features/analytics/redux/analytics-v2.actions';
import { UUID } from 'angular2-uuid';
import { fetchSearchHistoryLocations } from '../../find-loads/search-history/search-history.actions';
import { publishSearchSubmittedEvent } from 'app/repositories/analytics.repository';
import { setPage } from 'shared/components/pagination/pagination.reducer';
import { ConvertToSearchCriteria } from 'pages/find-loads-ver2/search/find-loads-search-functions';
import { FindLoadsSearchFormComponent } from 'pages/find-loads-ver2/search/find-loads-search-form.component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import isEqual from 'lodash.isequal';

import './search-area.component.scss';

const convertDistance = (value: number) => value && Math.round(convertDistanceToMetric(value));

interface OwnProps {
	onSearch: (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane?: boolean) => any;
	onLoadNumberSearch: (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane?: boolean) => any;
	onReset: () => any;
	abTesting: boolean;
	sendAnalytics: boolean;
}

export const SearchArea = ({ onSearch, onLoadNumberSearch, onReset, abTesting, sendAnalytics }: OwnProps) => {

	const dispatch = useDispatch();
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	// store selectors
	const { searchCriteria, results, user, carrier } = useSelector(s => ({
		searchCriteria: s.findLoads.searchCriteria || null,
		results: s.findLoads.results,
		user: s.auth.user,
		carrier: s.auth.carrier
	}));

	const isMetric = user.isMetric();
	const noLoads = !results?.loads;

	// component level state
	const [isLoadNumSearch, setIsLoadNumSearch] = useState(Boolean(searchCriteria?.loadnumber));
	const [submitPerformed, setSubmitPerformed] = useState(false);
	const prevSearchCriteriaRef = useRef(null);

	// forms
	const [filtersForm, updateFiltersForm] = useState(new FindLoadsFiltersForm());
	const [form, updateSearchForm] = useState(new FindLoadsSearchForm(isMetric));

	/**
	*
	* @param isLoadNumSearch the search is by load number
	*/
	const handleSelection = (_isLoadNumSearch: boolean) => {
		if (_isLoadNumSearch !== isLoadNumSearch) {
			clearForm();
			setIsLoadNumSearch(_isLoadNumSearch);
		}
	}

	// first render
	useEffect(() => {
		form.changes.subscribe(() => forceUpdate());
		form.valueChanges.subscribe(() => setSubmitPerformed(false));
		dispatch(fetchSearchHistoryLocations());
	}, []);

	useEffect(() => {
		form.changes.subscribe(() => forceUpdate());
		form.valueChanges.subscribe(() => setSubmitPerformed(false));

		if (searchCriteria) {
			setFromCriteria(searchCriteria);
			dispatch(setSubmitSearchEvent({ searchCriteria, searchCorrelationId: null, isButtonClick: false }));
		}
		if (!searchCriteria && noLoads && !submitPerformed) {
			dispatch(getSuggestedLoads());
		}
		if (isEqual(prevSearchCriteriaRef.current, searchCriteria)) {
			return;
		}
		prevSearchCriteriaRef.current = searchCriteria;
		if (searchCriteria && noLoads) {
			performSearch();
		}
		if (!searchCriteria) {
			form.markAsPristine();
		}
	}, [searchCriteria, isLoadNumSearch]);

	const setFromCriteria = (criteria: AvailableLoadSearchCriteriaJSON) => {
		setIsLoadNumSearch(!!criteria.loadNumber);
		const { originCountryCode, originCountryName, originStateProvinceCode, originCity, originRadiusMiles, originLatitude, originLongitude, originExampleZipCode } = criteria;
		const { destinationCountryCode, destinationCountryName, destinationStateProvinceCode, destinationCity, destinationRadiusMiles, destinationLatitude, destinationLongitude, destinationExampleZipCode } = criteria;

		form.reset();
		form.disable(true);

		setOriginOrDestination('origin', originCountryCode, originCountryName, originStateProvinceCode, originCity, originRadiusMiles, originLatitude, originLongitude, originExampleZipCode);
		setOriginOrDestination('destination', destinationCountryCode, destinationCountryName, destinationStateProvinceCode, destinationCity, destinationRadiusMiles, destinationLatitude, destinationLongitude, destinationExampleZipCode);

		form.get('dateRange.start').setValue(criteria.pickupStart ? moment(criteria.pickupStart) : null);
		form.get('dateRange.end').setValue(criteria.pickupEnd ? moment(criteria.pickupEnd) : null);
		form.get('equipment.type').setValue(criteria.mode || EquipmentType.All);
		form.get('equipment.extendedType').setValue(criteria.specializedEquipmentCode || EquipmentType.All);
		form.get('loadNumber').setValue(criteria.loadNumber || null);

		form.enable(true);
		form.markAsPristine();
	}

	const setOriginOrDestination = (prefix: string, countryCode: string, countryName: string, stateProvinceCode: string, city: string, radius: number, latitude: number, longitude: number, exampleZipCode) => {
		const country = countryName ? countryName : getCountryNameBasedOnCode(countryCode);
		let place = {
			displayName: `${city}, ${stateProvinceCode}, ${country}`,
			cityName: city,
			countryName: countryName,
			stateProvinceCode: stateProvinceCode,
			countryCode: countryCode,
			coordinate: {
				latitude: latitude,
				longitude: longitude
			},
			zipCodes: exampleZipCode ? [exampleZipCode] : null
		};

		form.get(`${prefix}.country`).setValue(countryCode);
		form.get(`${prefix}.stateProv`).setValue(stateProvinceCode);
		form.get(`${prefix}.city`).setValue(city);

		if (city && stateProvinceCode) {
			form.get(`${prefix}.place`).setValue(place);
		} else if (city) {
			place = {
				displayName: `${city}, ${country}`,
				cityName: city,
				countryName: countryName,
				stateProvinceCode: stateProvinceCode,
				countryCode: countryCode,
				coordinate: {
					latitude: latitude,
					longitude: longitude
				},
				zipCodes: exampleZipCode ? [exampleZipCode] : null
			};
			form.get(`${prefix}.place`).setValue(place);
		}

		form.get(`${prefix}.radius`).setValue((isMetric ? convertDistance(radius) : radius) || 100);
	}

	const onFiltersApplied = (filtersForm: FindLoadsFiltersForm) => {
		updateFiltersForm(filtersForm);
		performSearch(filtersForm);
	}

	const submitForm = (e: FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		setSubmitPerformed(true);
		performSearch();
	};

	const performSearch = (filters?: FindLoadsFiltersForm) => {
		if (!form.valid) {
			return form.touch();
		}

		dispatch(resetAvailableLoads());
		dispatch(resetErrorMessage());

		const shipmentNumber = form.get('loadNumber').value;

		if (shipmentNumber) {
			const searchCriteria = ConvertToSearchCriteria(form, isMetric);
			const searchCorrelationId = UUID.UUID();
			onLoadNumberSearch(searchCriteria, false);
			dispatch(setSubmitSearchEvent({ searchCriteria, searchCorrelationId, isButtonClick: false }));
			dispatch(setSearchCorrelationId(searchCorrelationId));
			if (sendAnalytics) {
				publishSearchSubmittedEvent('load', searchCorrelationId, searchCriteria, user, carrier?.carrierCode);
			}
			return;
		}

		const originForm = form.get('origin') as NavCarrierFormGroup;
		const destinationForm = form.get('destination') as NavCarrierFormGroup;

		// should not have errors origin or destination
		if (originForm.hasErrors() || destinationForm.hasErrors()) {
			return;
		}

		const searchFilters = filters || filtersForm

		const fullSearchCriteria = {
			...defaultSearchCriteria,
			...ConvertToSearchCriteria(form, isMetric, abTesting),
			...searchFilters?.toSearchCriteria(isMetric)
		};
		onSearch(fullSearchCriteria, false);

		const searchCorrelationId = UUID.UUID();

		dispatch(setSubmitSearchEvent({ searchCriteria: fullSearchCriteria, searchCorrelationId, isButtonClick: true }));
		dispatch(setSearchCorrelationId(searchCorrelationId));
		if (sendAnalytics) {
			publishSearchSubmittedEvent('origin-destination', searchCorrelationId, fullSearchCriteria, user, carrier?.carrierCode);
		}
		dispatch(setPage('find-loads-results', 1));
	}

	const clearForm = () => {
		form.reset();
		if (searchCriteria) {
			filtersForm?.reset();
			dispatch(searchModified());
			onReset(); // clears URL
		}
	};

	const resetForm = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		clearForm();
	};

	const setLocation = (prefix: string, location: LocationSelectGrouping) => {
		form.get(prefix + '.country').setValue(location.country);
		form.get(prefix + '.stateProv').setValue(location.stateProv);
		form.get(prefix + '.city').setValue(location.city);
		form.get(prefix + '.place').setValue(location.place);
		form.get(prefix + '.radius').setValue(location.radius);

		// don't trigger search modified since partial locations are not sent to the API on search
		// search criteria are effectively equal until the city has been set on a location
		if (location.place) {
			dispatch(searchModified());
		}
	};

	const setLoadNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = parseNumberInput(e.target.value);
		form.get('loadNumber').setValue(numericValue);
		dispatch(searchModified());
	}

	const setDateRange = (value: DateRangePickerV2Value) => {
		// We always want to set 00:00:00-23:59:59 for date times for the pick start/end search criteria.
		const start = value.startDate ? value.startDate.startOf('day') : null;
		const end = value.endDate ? value.endDate.endOf('day') : null;
		form.get('dateRange').setValue({ start, end });
		dispatch(searchModified());
	};

	const setEquipmentTypes = (values: EquipmentTypeValues) => {
		const { type, extendedType } = values;
		const equipmentTypeField = form.get('equipment.type');
		const extendedTypeField = form.get('equipment.extendedType');

		if (type !== equipmentTypeField.value || extendedType !== extendedTypeField.value) {
			dispatch(searchModified());
			if (type !== equipmentTypeField.value) {
				equipmentTypeField.setValue(type);
			}
			if (extendedType !== extendedTypeField.value) {
				extendedTypeField.setValue(extendedType);
			}
		}
	};

	const parseNumberInput = (value: string) => {
		const strippedInput = value.replace(/[^\d]/g, '');
		return (strippedInput !== '' ? Number(strippedInput) : strippedInput);
	}

	const loadNumSearchPerformed = (searchCriteria && searchCriteria.loadNumber);

	return (
		<div className="single-search search-component">
			{loadNumSearchPerformed &&
				<LoadNotFoundBanner
					results={results}
					changeSearchSelection={() => handleSelection(false)}
				/>
			}
			<FindLoadsSearchFormComponent
				form={form}
				isLoadNumSearch={isLoadNumSearch}
				submitPerformed={submitPerformed}
				handleSelection={handleSelection}
				setOriginLocation={(location) => setLocation('origin', location)}
				setDestinationLocation={(location) => setLocation('destination', location)}
				setDateRange={setDateRange}
				setEquipmentTypes={setEquipmentTypes}
				setLoadNumber={setLoadNumber}
				submitForm={submitForm}
				resetForm={resetForm}
			/>
			<FindLoadsFiltersModal onFiltersApplied={onFiltersApplied} isMetric={isMetric} searchCriteria={searchCriteria} />
		</div>
	);
}
