import { ChangeEvent } from 'react';
import { Translation } from 'shared/components/translation/translation.component';

import './min-max-form-group.component.scss';

interface MinMaxFormGroupProps {
  values;
  groupLabelKey: ResourceKey;
  fieldLabel: string;
  onMinChange: (e: ChangeEvent<HTMLInputElement>) => any;
  onMaxChange: (e: ChangeEvent<HTMLInputElement>) => any;
}

export const MinMaxFormGroup = ({ values, groupLabelKey, fieldLabel, onMinChange, onMaxChange }: MinMaxFormGroupProps) => {
  const classSuffix = groupLabelKey.replace(/_/, '-').toLowerCase();
  const groupLabelId = `min-max-form-group-label-${classSuffix}`;
  return (
    <div className="min-max-form-group" role="group" aria-labelledby={groupLabelId}>
      <div id={groupLabelId} className="h3">
        <Translation resource={groupLabelKey} />
      </div>
      <div className={`min-max-inputs min-max-inputs-${classSuffix}`}>
        <div>
          <label htmlFor={`min-${classSuffix}`}><Translation resource="MIN" /> {fieldLabel}</label>
          <input
            type="text"
            id={`min-${classSuffix}`}
            className="form-control"
            value={values.min ?? ''}
            onChange={onMinChange}
          />
        </div>
        <div>
          <label htmlFor={`max-${classSuffix}`}><Translation resource="MAX" /> {fieldLabel}</label>
          <input
            type="text"
            id={`max-${classSuffix}`}
            className="form-control"
            value={values.max ?? ''}
            onChange={onMaxChange} />
        </div>
      </div>
    </div>
  );
};
